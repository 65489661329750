.option > .active {
    background: #007bff;
}

.option > .active > h5 {
    color: #fff;
}

.option:hover > div > h5 {
    transform: scale(1.05);
    transition-duration: 0.2s;
}